.bar-footer{
    background-color: rgb(12, 12, 90);
    color: white;
    padding: 15px;
}

.bar-footer2{
    background-color: #0a0330;
    color: white;
    padding: 15px;
}