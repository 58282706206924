@import url('https://fonts.googleapis.com/css2?family=Sora&display=swap');


body{
  font-size: 14px;
}



* {
  font-family: 'Sora', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}




.section2{
  background-color: white;
}


.telefone-brasileiro-input{
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
}

.telefone-brasileiro-input:focus{
  border-color: cadetblue !important;
  border-bottom-color: cadetblue !important;
  border-width: 1px;
  border-style: solid;
  border-color: cadetblue;
}