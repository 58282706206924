#components-layout-demo-responsive {
  height: 32px;
  margin: 16px;
  background: #0a0330;
}

.logo {
  margin: 10;
}


.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgb(182, 15, 15);
}

.site-layout .site-layout-background {
  background: #fff;
}


.dashTicket {
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  border-color: rgb(238, 236, 236);
  border-width: 0.5px;
  border-style: solid;
  font-size: 14px;
  cursor: pointer;
}

.dashTicket-item1 {
  display: flex;
  flex-direction: column;
  width: 90px;
  background-color: #0a0330;
  justify-content: center;
  align-items: center;
  
}

.dashTicket-item2 {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  width: 100%;
}


.box {
  display: flex;
  flex-flow: column;
  height: 100vh;
}

.box .content {
  flex-grow: 1;
  flex-basis: auto;
}

.box .footer {
  flex-grow: 0;
  flex-basis: auto;
}