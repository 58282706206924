.div-princing-1 {
  text-align: center;
  color: royalblue;
}

.div-princing-2{
  margin-top:-10px; 
  color:royalblue;
}

.span-princing-1{
  vertical-align:super;
}

.span-princing-2{
  vertical-align:central;
  font-size:50px;font-family: Montserrat;
}

.span-princing-3{
  vertical-align:super;
}

.pricing-table {
  background-color: #eee;
  font-family: 'Montserrat', sans-serif;
}

  .pricing-table .block-heading {
      padding-top: 50px;
      margin-bottom: 40px;
      text-align: center;
  }

      .pricing-table .block-heading h2 {
          color: #3b99e0;
      }

      .pricing-table .block-heading p {
          text-align: center;
          max-width: 420px;
          margin: auto;
          opacity: 0.7;
      }

  .pricing-table .heading {
      text-align: center;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .pricing-table .item {
      background-color: #ffffff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
      border-top: 2px solid #5ea4f3;
      padding: 30px;
      overflow: hidden;
      position: relative;
  }

  .pricing-table .col-md-5:not(:last-child) .item {
      margin-bottom: 30px;
  }

  .pricing-table .item button {
      font-weight: 600;
  }

  .pricing-table .ribbon {
      width: 160px;
      height: 32px;
      font-size: 12px;
      text-align: center;
      color: #fff;
      font-weight: bold;
      box-shadow: 0px 2px 3px rgba(136, 136, 136, 0.25);
      background: #4dbe3b;
      transform: rotate(45deg);
      position: absolute;
      right: -42px;
      top: 20px;
      padding-top: 7px;
  }

  .pricing-table .item p {
      text-align: center;
      margin-top: 20px;
      opacity: 0.7;
  }

  .pricing-table .features .feature {
      font-weight: 600;
  }

  .pricing-table .features h4 {
      text-align: center;
      font-size: 18px;
      padding: 5px;
  }

  .pricing-table .price h4 {
      margin: 15px 0;
      font-size: 45px;
      text-align: center;
      color: #2288f9;
  }

  .pricing-table .buy-now button {
      text-align: center;
      margin: auto;
      font-weight: 600;
      padding: 9px 0;
  }

.tooltip2 {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

  .tooltip2 .tooltiptext {
      visibility: hidden;
      width: 200px;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      z-index: 1;
      top: -5px;
      left: 110%;
  }

      .tooltip2 .tooltiptext::after {
          content: " ";
          position: absolute;
          top: 50%;
          right: 100%; /* To the left of the tooltip */
          margin-top: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: transparent black transparent transparent;
      }

  .tooltip2:hover .tooltiptext {
      visibility: visible;
  }

  .item{
    margin: 10px;
  }